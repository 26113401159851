import { Nullish } from '~/@types/generic'
import { METAFIELDS } from '~/lib/shopify/metafields'
import { ProductPageTypes } from '~/lib/shopify/metafields/constants'

import {
  UnlikelyMetafields,
  UnlikelyProduct,
} from '@unlikelystudio/commerce-connector'

import findByProperty from '~/utils/find-by-property'

export function formatCustomerMetafieldsInput(obj) {
  return Object.keys(obj)?.map((key) => {
    const metafieldConfig = findByProperty(
      Object.values(METAFIELDS),
      'key',
      key,
    )

    return {
      ...metafieldConfig,
      value: obj?.[key],
    }
  })
}

export function getMetafieldValue<T>(key, metafields: UnlikelyMetafields) {
  return (metafields?.[key?.processedKey]?.value as T) ?? null
}

export function isPageFragranceFromMetafields(
  product: Nullish<Partial<Pick<UnlikelyProduct, 'metafields'>>>,
) {
  return (
    getMetafieldValue<ProductPageTypes>(
      METAFIELDS.PRODUCT_PAGE_TYPE,
      product?.metafields,
    ) === 'product_page_fragrance'
  )
}

export function isPageSetFromMetafields(
  product: Nullish<Partial<Pick<UnlikelyProduct, 'metafields'>>>,
) {
  return (
    getMetafieldValue<ProductPageTypes>(
      METAFIELDS.PRODUCT_PAGE_TYPE,
      product?.metafields,
    ) === 'product_page_set'
  )
}
