import linkResolver from '~/lib/link-resolver'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import { Cart } from '~/hooks/cart/useGetCart'

import { customAttributesAsObject } from '~/utils/custom-attributes'
import getShopifyDiscountPercentage from '~/utils/get-shopify-discount-percentage'

import serializePrice from '~/data/serialize-price'

function serializeVariantsSelector(
  variants: Cart['lines'][0]['merchandise']['product']['variants'],
) {
  const availableVariants =
    variants
      ?.filter((variant) => variant?.availableForSale)
      ?.map((variant) => variant?.id)
      ?.filter(Boolean) ?? []

  return (
    variants?.map((variant) => {
      const id = variant?.id
      const disabled = !availableVariants?.includes(id)
      return {
        value: id,
        disabled,
        label: variant?.title,
      }
    }) ?? null
  )
}

export default function serializeCartProducts(
  products: Cart['lines'],
  locale: string,
) {
  return products?.map((product) => {
    const attributes = customAttributesAsObject(product?.attributes) ?? null

    const variantsSelector = serializeVariantsSelector(
      product?.merchandise?.product?.variants ?? null,
    )

    const currentVariant = product?.merchandise

    // Getting the qty available to avoid adding OOS products into the cart
    const quantityAvailable =
      product?.merchandise?.product?.variants?.find(
        (variant) => variant.id === product?.merchandise?.id,
      )?.quantityAvailable ?? false

    return {
      id: product?.id ?? null,
      quantityAvailable,
      variantId: currentVariant?.id ?? null,
      variantsSelector: variantsSelector,
      compareAtPrice:
        serializePrice(
          locale,
          currentVariant?.compareAtPrice?.currencyCode,
          currentVariant?.compareAtPrice?.amount * product?.quantity,
        ) ?? null,
      compareAtPriceAmount: currentVariant?.compareAtPrice?.amount ?? null,
      priceAmount: currentVariant?.price?.amount.toString() ?? null,
      computedDiscountPercentage:
        getShopifyDiscountPercentage(
          currentVariant?.compareAtPrice?.amount,
          currentVariant?.price?.amount,
        ) ?? null,
      price: attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.customPrice]
        ? serializePrice(
            locale,
            currentVariant?.price?.currencyCode,
            +attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.customPrice] *
              product?.quantity,
          )
        : serializePrice(
            locale,
            currentVariant?.price?.currencyCode,
            currentVariant?.price?.amount * product?.quantity,
          ) ?? null,

      // /* It's a fallback. If the custom attribute `setsName` is not available, it will use `name`
      // instead. */
      title:
        attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.setsName] ??
        attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.name] ??
        null,
      size: currentVariant.title ?? null,
      quantity: product?.quantity ?? null,
      // /* It's a fallback. If the custom attribute `setsImage` is not available, it will use `image`
      // instead. */
      imageSrc:
        attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.setsImage] ??
        attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.image] ??
        null,
      attributes: attributes ?? null,
      href:
        attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.slug] &&
        attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType]
          ? linkResolver(
              {
                uid: attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.slug] ?? null,
                type:
                  attributes?.[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ?? null,
              },
              locale,
            )
          : null,
      metafields: product?.merchandise?.product?.metafields,
    }
  })
}
