import React, { createContext, useContext, useMemo } from 'react'

import { MetaProps } from '~/components/Abstracts/Meta'

import { GlobalProps } from '~/data/global-data'
import { TDocument } from '~/data/page-data/serializer'

type TGlobalData = {
  global: GlobalProps
  metas: MetaProps
  document: TDocument
}

const Context = createContext<TGlobalData | null>(null)

export function useGlobalData() {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useGlobalData must be used within a GlobalDataProvider')
  }

  return context
}

export interface GlobalDataProviderProps {
  children: JSX.Element | JSX.Element[]
  value: TGlobalData
}

export default function GlobalDataProvider({
  value,
  children,
}: GlobalDataProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>
}
