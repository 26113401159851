import { CartProps } from '~/@types/cart'

import { Cart } from '~/hooks/cart/useGetCart'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import serializePrice from '~/data/serialize-price'

function applyLocaleToCart(url, locale) {
  if (!url) return
  const [lang] = locale?.split('-') ?? [locale]
  const processedUrl = new URL(url)
  processedUrl.searchParams.append('locale', lang)
  return processedUrl?.href
}

export default function serializeCart(
  cart: Cart,
  locale: string,
): Omit<CartProps, 'products'> {
  const attributes = customAttributesAsObject(cart?.attributes ?? [])

  return {
    id: cart?.id ?? null,
    attributes,
    // completed: cart?.completedAt ? true : false,
    totalAmount: cart?.cost?.totalAmount?.amount ?? null,
    total: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalAmount?.currencyCode,
          cart?.cost?.totalAmount?.amount,
        )
      : null,
    subtotal: cart
      ? serializePrice(
          locale,
          cart?.cost?.subtotalAmount?.currencyCode,
          cart?.cost?.subtotalAmount?.amount,
        )
      : null,
    totalTax: cart
      ? serializePrice(
          locale,
          cart?.cost?.totalTaxAmount?.currencyCode,
          cart?.cost?.totalTaxAmount?.amount,
        )
      : null,
    checkoutUrl: applyLocaleToCart(cart?.checkoutUrl, locale) ?? null,
    productsQuantity: cart?.lines?.reduce?.((previousValue, line) => {
      return previousValue + (line?.quantity ?? 0)
    }, 0),
  }
}
