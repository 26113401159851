import { PRISMIC_TYPES } from '~/lib/prismic-types'
import { SHOPIFY_CUSTOM_ATTRIBUTES } from '~/lib/shopify/constants'

import {
  OrderElement,
  OrderElementItem,
  OrderItems,
} from '~/components/Account/OrdersGrid/types'

import { customAttributesAsObject } from '~/utils/custom-attributes'

import { GlobalProps } from '~/data/global-data'
import serializeDate from '~/data/serialize-date'
import serializePrice from '~/data/serialize-price'

/**
 * The function checks if a list of products contains both fragrance and fashion items.
 * @param products - An array of products. Each product object has a `attributes` property, which
 * is an object containing custom attributes for the product. The custom attributes object has a
 * property `prismicType`, which represents the type of the product. The possible values for
 * `prismicType` are `PRODUCT_FRAGRANCE` or `PRODUCT_FASHION`.
 * @returns a boolean value indicating whether the given array of products contains both fragrance and
 * fashion products.
 */
function getIsOrderMixed(products) {
  let hasFragrance = false
  let hasFashion = false

  products?.forEach((product) => {
    if (
      product?.attributes[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
        PRISMIC_TYPES.PRODUCT_FRAGRANCE &&
      !hasFragrance
    ) {
      hasFragrance = true
    }

    if (
      product?.attributes[SHOPIFY_CUSTOM_ATTRIBUTES.prismicType] ===
        PRISMIC_TYPES.PRODUCT_FASHION &&
      !hasFashion
    ) {
      hasFashion = true
    }
  })

  return hasFragrance && hasFashion
}

/**
 * The function `sortFragranceProducts` sorts an array of `OrderElementItem` objects based on certain
 * properties.
 * @param {OrderElementItem[]} items - An array of OrderElementItem objects. Each OrderElementItem
 * object represents a fragrance product and has the following properties:
 * @returns the sorted array of fragrance products.
 */
function sortFragranceProducts(items: OrderElementItem[]) {
  function sortItems(a: OrderElementItem, b: OrderElementItem) {
    if (!a.isGift && !a.isSample && !a.isPouch) return -1
    if (!b.isGift && !b.isSample && !b.isPouch) return 1

    if (a.isSample && !b.isSample) return -1
    if (!a.isSample && b.isSample) return 1

    if (a.isPouch && !b.isPouch) return 1
    if (!a.isPouch && b.isPouch) return -1

    return 0
  }

  items.sort(sortItems)

  return items
}

/**
 * The function `serializeAndSortProducts` takes in a list of products and a locale, and returns an
 * object containing sorted fragrance products, fashion products, and a flag indicating if the order
 * contains a mix of products.
 * @param {any} products - An array of product objects. Each product object should have the following
 * properties:
 * @param {string} locale - The `locale` parameter is a string that represents the language and region
 * settings for formatting the prices in the serialized products. It is used in the `serializePrice`
 * function to format the currency and amount values.
 * @returns an object of type `OrderItems` which contains the following properties:
 */
function serializeAndSortProducts(products: any, locale: string): OrderItems {
  let fragranceProducts: OrderElementItem[] = []
  const fashionProducts: OrderElementItem[] = []
  const isOrderMixed = getIsOrderMixed(products)

  const baseItems = products?.map((product) => ({
    id: product?.variant?.id ?? null,
    name: product?.attributes?._name ?? product?.title ?? null,
    variants: product?.variant?.selectedOptions ?? null,
    image: product?.variant?.image?.url ?? null,
    price:
      serializePrice(
        locale,
        product?.originalTotalPrice?.currencyCode,
        product?.originalTotalPrice?.amount,
      ) ??
      serializePrice(locale, product?.originalTotalPrice?.currencyCode, '0') ??
      null,
    quantity: product?.quantity ?? null,
    total: serializePrice(
      locale,
      product?.originalTotalPrice?.currencyCode,
      product?.originalTotalPrice?.amount * product?.quantity,
    ),
    productType: product?.attributes?._prismicType ?? null,
    isGift: product?.attributes?._gift === 'true',
    isSample: product?.attributes?._sample === 'true',
    isPouch: product?.attributes?._pouch === 'true',
  }))

  baseItems?.forEach((item) => {
    if (item.productType === PRISMIC_TYPES.PRODUCT_FRAGRANCE) {
      fragranceProducts.push(item)
    } else if (item.productType === PRISMIC_TYPES.PRODUCT_FASHION) {
      fashionProducts.push(item)
    }
  })

  // Sorting fragrance only
  fragranceProducts = sortFragranceProducts(fragranceProducts)

  return {
    fragranceProducts,
    fashionProducts,
    isOrderMixed,
  }
}

export default function serializeOrder({
  order,
  locale,
  parcelLabPages,
}: {
  order: any
  locale: string
  parcelLabPages: GlobalProps['parcelLab']
}): OrderElement {
  const products = order?.lineItems?.map((node) => ({
    ...node,
    attributes: customAttributesAsObject(node?.customAttributes ?? []),
  }))

  return {
    id: order?.id,
    reference: order?.name,
    date:
      serializeDate(order?.processedAt, locale, { year: 'numeric' }) ?? null,
    total:
      serializePrice(
        locale,
        order?.totalPrice?.currencyCode,
        order?.totalPrice?.amount,
      ) ?? null,
    status: order?.fulfillmentStatus,
    shippingAddress: order?.shippingAddress ?? null,
    trackingPage: parcelLabPages?.trackingPage ?? null,
    returnsPage: parcelLabPages?.returnsPage ?? null,
    items: serializeAndSortProducts(products, locale),
  }
}
